import { Component, OnInit, Input } from '@angular/core';
import { Kenteken } from '../kenteken.interface';

@Component({
  selector: 'app-search-element',
  templateUrl: './search-element.page.html',
  styleUrls: ['./search-element.page.scss'],
})
export class SearchElementPage implements OnInit {
  @Input() kenteken: Kenteken;
  constructor() { }

  ngOnInit() {
  }

}
